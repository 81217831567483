import {
  pluginLinkLoadable as pluginLink,
  pluginImageLoadable as pluginImage,
  pluginVideoLoadable as pluginVideo,
  pluginHtmlLoadable as pluginHtml,
  pluginGiphyLoadable as pluginGiphy,
  pluginFileUploadLoadable as pluginFileUpload,
  pluginLinkPreviewLoadable as pluginLinkPreview,
  pluginCollapsibleListLoadable as pluginCollapsibleList,
  pluginPollLoadable as pluginPoll,
  pluginGalleryLoadable as pluginGallery,
} from '@wix/ricos/loadable';

import {
  pluginHashtagViewer as pluginHashtag,
  pluginDividerViewer as pluginDivider,
  pluginMentionsViewer as pluginMentions,
  pluginCodeBlockViewer as pluginCodeBlock,
  pluginTextColorViewer as pluginTextColor,
  pluginTextHighlightViewer as pluginTextHighlight,
  pluginEmojiViewer as pluginEmoji,
  pluginVerticalEmbedViewer as pluginVerticalEmbed,
  pluginSpoilerViewer as pluginSpoiler,
} from '@wix/ricos';

import '@wix/ricos/css/plugin-hashtag-viewer.global.css';
import '@wix/ricos/css/plugin-divider-viewer.global.css';
import '@wix/ricos/css/plugin-mentions-viewer.global.css';
import '@wix/ricos/css/plugin-vertical-embed-viewer.global.css';
import '@wix/ricos/css/plugin-spoiler-viewer.global.css';
import { PLUGINS } from '@wix/communities-forum-client-commons';

export const viewerPluginModules = {
  [PLUGINS.IMAGE]: pluginImage,
  [PLUGINS.VIDEO]: pluginVideo,
  [PLUGINS.FILE_UPLOAD]: pluginFileUpload,
  [PLUGINS.GIPHY]: pluginGiphy,
  [PLUGINS.EMOJI]: pluginEmoji,
  [PLUGINS.CODE_BLOCK]: pluginCodeBlock,
  [PLUGINS.COLLAPSIBLE_LIST]: pluginCollapsibleList,
  [PLUGINS.DIVIDER]: pluginDivider,
  [PLUGINS.HTML]: pluginHtml,
  [PLUGINS.VERTICAL_EMBED]: pluginVerticalEmbed,
  [PLUGINS.LINK]: pluginLink,
  [PLUGINS.LINK_PREVIEW]: pluginLinkPreview,
  [PLUGINS.TEXT_COLOR]: pluginTextColor,
  [PLUGINS.TEXT_HIGHLIGHT]: pluginTextHighlight,
  [PLUGINS.HASHTAG]: pluginHashtag,
  [PLUGINS.MENTIONS]: pluginMentions,
  [PLUGINS.SPOILER]: pluginSpoiler,
  [PLUGINS.POLLS]: pluginPoll,
  [PLUGINS.GALLERY]: pluginGallery,
};
