import { flowRight } from 'lodash';
import React from 'react';
import hoistNonReactStatics from 'hoist-non-react-statics';
import { getDisplayName, APP_TEXT_COLOR_PATH } from '@wix/communities-forum-client-commons';
import withSettingsColor from './with-settings-color';
import withCardBackgroundColor from './with-card-background-color';
import withButtonColor from './with-button-color';
import withDeviceType from './with-device-type';
import type { WixRicosTheme } from '@wix/ricos';

type WrappedComponentType = { rceTheme: WixRicosTheme };

export const withRceTheme = (WrappedComponent: React.ComponentType<WrappedComponentType>) => {
  type WrapperProps = {
    textColor: string;
    cardBackgroundColor: string;
    buttonColor: string;
    isMobile: boolean;
    origin: string;
  };

  const Wrapper: React.FC<WrapperProps> = ({
    textColor,
    cardBackgroundColor,
    buttonColor,
    isMobile,
    origin,
    ...rest
  }) => {
    const parentClass: WixRicosTheme['parentClass'] = `rce-upper-scope-${origin}`;

    const customStyles: WixRicosTheme['customStyles'] = React.useMemo(
      () => ({
        h2: {
          fontSize: isMobile ? '22px' : '28px',
          lineHeight: isMobile ? '30px' : '36px',
        },
        h3: {
          fontSize: isMobile ? '18px' : '22px',
          lineHeight: isMobile ? '26px' : '33px',
        },
        quote: {
          fontSize: isMobile ? '16px' : '24px',
          lineHeight: isMobile ? '1.61' : '32px',
          borderWidth: isMobile ? '3px' : '2px',
          paddingInlineStart: '18px',
          paddingTop: isMobile ? '6px' : '10px',
          paddingBottom: isMobile ? '6px' : '10px',
        },
        codeBlock: {
          margin: '0',
          padding: '16px',
          lineHeight: isMobile ? '20px' : '1.8',
          fontSize: '12px',
        },
        footerToolbar: {
          marginTop: '22px',
        },
      }),
      [isMobile],
    );

    const settingsStyles: WixRicosTheme['settingsStyles'] = React.useMemo(
      () => ({
        dividers: {
          height: '0',
        },
        modals: {
          drawerZIndex: 'calc(var(--above-all-z-index, 100000) + 1000)',
        },
      }),
      [],
    );

    const rceTheme: WixRicosTheme = React.useMemo(
      () => ({
        parentClass,
        customStyles,
        settingsStyles,
        palette: {
          bgColor: cardBackgroundColor,
          textColor,
          actionColor: buttonColor,
          fallbackColor: '#000000',
          disabledColor: buttonColor,
        },
      }),
      [parentClass, customStyles, settingsStyles, cardBackgroundColor, textColor, buttonColor],
    );

    return (
      <div className={parentClass}>
        <WrappedComponent rceTheme={rceTheme} {...rest} />
      </div>
    );
  };

  Wrapper.displayName = `WithFontClassNames(${getDisplayName<WrappedComponentType>(
    WrappedComponent,
  )})`;

  hoistNonReactStatics(Wrapper, WrappedComponent);

  return flowRight(
    withSettingsColor({
      path: APP_TEXT_COLOR_PATH,
      propName: 'textColor',
      siteColorFallback: 'color-5',
    }),
    withCardBackgroundColor,
    withButtonColor,
    withDeviceType,
  )(Wrapper);
};
