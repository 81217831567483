// DANGER!: if you change this, you should also change it in wix media console
export const supportedMimeTypes = [
  'image/*',
  'video/*',
  'audio/*',
  'text/*',
  'application/json',
  'iapplication/vnd.amazon.ebook',
  'application/x-bzip',
  'application/x-bzip2',
  'application/msword',
  'application/epub+zip',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/vnd.oasis.opendocument.presentation',
  'application/vnd.oasis.opendocument.spreadsheet',
  'application/vnd.oasis.opendocument.text',
  'application/pdf',
  'application/zip',
  'application/x-7z-compressed',
  'application/vnd.ms-powerpoint',
  'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  'application/x-rar-compressed',
  'application/x-tar',
  'application/vnd.ms-excel',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
];
