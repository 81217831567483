import React, { useRef } from 'react';
import { isString, pick, flowRight, noop } from 'lodash';
import {
  createHashtagHref,
  createHashtagPath,
  HTML_IFRAME_SRC,
  MAX_PINTEREST_IMAGE_SIZE,
} from '../components/rich-content-editor/utils';
import { RCE_FILE_DOWNLOAD_WILL_BEGIN_SHORTLY } from '../components/messages/message-types';
import { supportedMimeTypes } from '../../common/services/wix-media/supported-mime-types';
import { isIos } from '../../common/services/detect-platform';
import {
  customStyleFn,
  getTextColorSchema,
  styleSelectionPredicate,
  viewerCustomStyleFn,
} from '../components/rich-content-editor/text-color-utils';

import {
  getImageUrl,
  createSiteMembersFetchServiceDebounced,
  URI,
  PLUGINS,
} from '@wix/communities-forum-client-commons';
import {
  getEnabledRcePlugins,
  getIsSocialSharingLinksEnabled,
} from '../selectors/app-settings-selectors';
import {
  getApiBaseUrl,
  getInstance,
  getLanguage,
  getRicosBiParams,
} from '../../common/store/basic-params/basic-params-selectors';
import { getLocation, getSectionUrl } from '../../common/store/location/location-selectors';
import { DEFAULT_PLUGINS } from '../components/rich-content-editor/constants';
import { isMemberAreaInstalled } from '../../common/store/communities-context/communities-context-selectors';
import { pluginTypeMap } from '../containers/wix-comments/plugins';
import { getVisibleVerticalEmbeds } from '../selectors/vertical-embed-selectors';
import { resolveFileUrl } from '@wix/ricos';
import { getMetaSiteId } from '../../common/store/instance-values/instance-values-selectors';
import { connect } from '../../common/components/runtime-context';
import withDeviceType from './with-device-type';
import withTranslate from '../../common/components/with-translate/with-translate';
import withAuth from './with-auth';
import { RootState } from '../types/store-types';

const GIPHY_SDK_KEY = '8LVphUXpyDK77BY7BhYMXgFU6TwhSKkA';

export type PluginsConfig = Record<
  string,
  {
    editorConfig?: Record<string, any>;
    viewerConfig?: Record<string, any>;
  }
>;

export const withRcePluginsConfig = (Comp: React.ComponentType) => {
  const WithEditorPluginConfigs = ({
    showPin,
    pageUrl,
    isMobile,
    showMessage,
    requestFileDownloadUrlPromisified,
    fileDownloadRequest,
    metaSiteId,
    instance,
    style,
    navigateWithinForum,
    enabledPlugins,
    isAuthenticated,
    enabledVerticalEmbedPlugins,
    apiBaseUrl,
    sectionUrl,
    isMembersAreaInstalled,
    navigateToProfile,
    originalProps,
  }: any) => {
    const userColorsRef = useRef<string[]>([]);

    const getImagePluginConfig = React.useCallback(
      (isViewer) => {
        const config = {
          imageProps: showPin
            ? (src: any) => ({
                'data-pin-url': pageUrl,
                'data-pin-media': isString(src)
                  ? src
                  : getImageUrl(src, MAX_PINTEREST_IMAGE_SIZE, MAX_PINTEREST_IMAGE_SIZE),
              })
            : { 'data-pin-nopin': true },
        };

        return isViewer
          ? { ...config, disableExpand: isMobile, toolbar: { hidden: ['sizeFullWidth'] } }
          : config;
      },
      [showPin, pageUrl, isMobile],
    );

    const getVideoPluginConfig = React.useCallback(() => {
      return {
        toolbar: {
          hidden: [],
        },
        getVideoUrl: (src: any) => `https://video.wixstatic.com/${src.pathname}`,
        enableCustomUploadOnMobile: true,
      };
    }, []);

    const getFileUploadPluginConfig = React.useCallback(() => {
      return {
        accept: supportedMimeTypes.join(','),
      };
    }, []);

    const getFileUploadPluginViewerConfig = React.useCallback(() => {
      return {
        resolveFileUrl: (imgEntityData: any) => {
          // This is needed for backwards compatibility.
          // Forum used to upload files via Node API service, which resulted in different entity structure
          // We are assuming that if entity has a 'path', it was uploaded via Node API, otherwise
          // It's assumed that RICOS upload logic was used.
          if (!imgEntityData.path) {
            return resolveFileUrl(metaSiteId, instance)(imgEntityData);
          }

          isIos() && showMessage(RCE_FILE_DOWNLOAD_WILL_BEGIN_SHORTLY);
          const getBiEventData = (isSuccessful: boolean) => ({
            fileId: imgEntityData.id,
            fileExtension: imgEntityData.type,
            mimeType: imgEntityData.mimeType,
            size: imgEntityData.size,
            isSuccessful,
            origin,
          });

          return requestFileDownloadUrlPromisified(imgEntityData.path)
            .then((data: any) => {
              fileDownloadRequest(getBiEventData(true));
              return data.downloadUrl;
            })
            .catch(() => fileDownloadRequest(getBiEventData(false)));
        },
      };
    }, [metaSiteId, instance]);

    const getColorPluginsConfig = React.useCallback(
      (isHighlight) => {
        const colorScheme = getTextColorSchema(style);
        return {
          colorScheme,
          styleSelectionPredicate: styleSelectionPredicate(colorScheme),
          customStyleFn: customStyleFn(viewerCustomStyleFn(colorScheme, isHighlight)),
          getUserColors: () => userColorsRef.current,
          onColorAdded: (c: string) => (userColorsRef.current = [...userColorsRef.current, c]),
        };
      },
      [style],
    );

    const getColorPluginsViewerConfig = React.useCallback(
      (isHighlight) => {
        const colorScheme = getTextColorSchema(style);
        return {
          customStyleFn: viewerCustomStyleFn(colorScheme, isHighlight),
          styleSelectionPredicate: styleSelectionPredicate(colorScheme),
        };
      },
      [style],
    );

    const getSupportedSpoilerPlugins = React.useCallback(() => {
      return isAuthenticated
        ? [PLUGINS.IMAGE, PLUGINS.VIDEO]
            .filter((p) => enabledPlugins.includes(p))
            .map((p) => pluginTypeMap[p])
        : [];
    }, [isAuthenticated]);

    const onHashTagClick = React.useCallback(
      (event) => {
        event.preventDefault();
        event.stopPropagation();
        const pathname = createHashtagPath(event.target.innerText);
        navigateWithinForum(pathname);
      },
      [navigateWithinForum],
    );

    const filterEnabledPlugins = React.useCallback(
      (plugins) => {
        const validPlugins = [...DEFAULT_PLUGINS, ...enabledPlugins];
        return plugins.filter((p: string) => validPlugins.includes(p));
      },
      [enabledPlugins],
    );

    const getPluginsConfig = React.useCallback(() => {
      const plugins = {
        [PLUGINS.IMAGE]: {
          editorConfig: getImagePluginConfig(false),
          viewerConfig: getImagePluginConfig(true),
        },
        [PLUGINS.VIDEO]: {
          editorConfig: getVideoPluginConfig(),
          viewerConfig: {
            // Function is invoked when rendering video which has relative URL.
            // You should take the pathname and form a full URL.
            getVideoUrl: (src: any) => `https://video.wixstatic.com/${src.pathname}`,
          },
        },
        [PLUGINS.FILE_UPLOAD]: {
          editorConfig: getFileUploadPluginConfig(),
          viewerConfig: getFileUploadPluginViewerConfig(),
        },
        [PLUGINS.GIPHY]: {
          editorConfig: {
            giphySdkApiKey: GIPHY_SDK_KEY,
            componentDataDefaults: { config: { size: 'small', alignment: 'center' } },
            insertToolbars: ['SIDE', 'FOOTER'],
          },
          viwerConfig: { giphySdkApiKey: GIPHY_SDK_KEY },
        },
        [PLUGINS.HTML]: {
          editorConfig: {
            htmlIframeSrc: HTML_IFRAME_SRC,
          },
          viewerConfig: {
            htmlIframeSrc: HTML_IFRAME_SRC,
          },
        },
        [PLUGINS.VERTICAL_EMBED]: {
          editorConfig: {
            exposeEmbedButtons: enabledVerticalEmbedPlugins,
          },
        },
        [PLUGINS.TEXT_COLOR]: {
          editorConfig: getColorPluginsConfig(false),
          viewerConfig: getColorPluginsViewerConfig(false),
        },
        [PLUGINS.TEXT_HIGHLIGHT]: {
          editorConfig: getColorPluginsConfig(true),
          viewerConfig: getColorPluginsViewerConfig(true),
        },
        [PLUGINS.HASHTAG]: {
          editorConfig: {
            createHref: () => '',
            onClick: noop,
          },
          viewerConfig: {
            onClick: onHashTagClick,
            createHref: createHashtagHref(sectionUrl),
          },
        },
        [PLUGINS.MENTIONS]: {
          editorConfig: {
            getMentions: createSiteMembersFetchServiceDebounced({ instance, apiBaseUrl }),
            entryHeight: 30,
            visibleItemsBeforeOverflow: 5,
            zIndex: isMobile ? 110000 : 5100,
          },
          viewerConfig: {
            onMentionClick: isMembersAreaInstalled
              ? (mention: { id: string; slug: string }) =>
                  navigateToProfile({ memberId: mention.id, memberSlug: mention.slug })
              : null,
            getMentionLink: noop,
          },
        },
        [PLUGINS.SPOILER]: {
          editorConfig: {
            supportedPlugins: getSupportedSpoilerPlugins(),
          },
        },
      };
      return plugins;
    }, [
      enabledVerticalEmbedPlugins,
      instance,
      apiBaseUrl,
      sectionUrl,
      isMembersAreaInstalled,
      navigateToProfile,
      getImagePluginConfig,
      getVideoPluginConfig,
      getFileUploadPluginConfig,
      getFileUploadPluginViewerConfig,
      getColorPluginsConfig,
      getColorPluginsViewerConfig,
      onHashTagClick,
      getSupportedSpoilerPlugins,
      isMobile,
    ]);

    return (
      <Comp
        {...originalProps}
        pluginsConfig={React.useMemo(() => getPluginsConfig(), [getPluginsConfig])}
        filterEnabledPlugins={filterEnabledPlugins}
      />
    );
  };

  const mapRuntimeToProps = (state: RootState, ownProps: any, actions: any, host: any) => {
    return {
      style: host.style,
      locale: getLanguage(state),
      // @ts-expect-error
      pageUrl: new URI(getLocation(state).url).search('').toString(),
      instance: getInstance(state),
      sectionUrl: getSectionUrl(state),
      enabledPlugins: getEnabledRcePlugins(state, host.style),
      showPin: getIsSocialSharingLinksEnabled(state, host.style),
      showMessage: actions.showMessage,
      apiBaseUrl: getApiBaseUrl(state),
      ricosBiParams: getRicosBiParams(state),
      experiments: state.experiments,
      enabledVerticalEmbedPlugins: getVisibleVerticalEmbeds(state, host.style),
      requestFileDownloadUrlPromisified: actions.requestFileDownloadUrlPromisified,
      fileDownloadRequest: actions.fileDownloadRequest,
      originalProps: ownProps,
      navigateWithinForum: actions.navigateWithinForum,
      isMembersAreaInstalled: isMemberAreaInstalled(state),
      navigateToProfile: actions.navigateToProfile,
      metaSiteId: getMetaSiteId(state),
    };
  };

  return flowRight(
    connect(mapRuntimeToProps),
    withTranslate,
    withDeviceType,
    withAuth,
  )(WithEditorPluginConfigs);
};
