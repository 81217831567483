import { flowRight, mapValues } from 'lodash';
import React from 'react';
import { connect } from '../../../common/components/runtime-context';
import { WixRicosViewer } from '@wix/ricos';
import '@wix/ricos/css/ricos-viewer.global.css';
import { getIsPinterestPinEnabled } from '../../selectors/app-settings-selectors';
import { getLocation } from '../../../common/store/location/location-selectors';
import {
  isSeo,
  isEditor,
  getLanguage,
  isSSR,
  isPreview,
  getRicosBiParams,
  getInstance,
} from '../../../common/store/basic-params/basic-params-selectors';
import { PLUGINS } from '@wix/communities-forum-client-commons';
import getOuterUrl from '../../services/get-outer-url';
import withDeviceType from '../../hoc/with-device-type';
import { isMemberAreaInstalled } from '../../../common/store/communities-context/communities-context-selectors';
import { mapConfigToPlugins } from './plugins';
import styles from './rich-content-viewer.scss';
import { withRcePluginsConfig } from '../../hoc/with-rce-plugins-config';

import withAuth from '../../hoc/with-auth';
import { withRceTheme } from '../../hoc/with-rce-theme';
import { MemberCardAsyncLoadable } from '../../containers/member-card-async/member-card-async-loadable';

// This wrapper is needed for React render optimization
const RicosWrapper = React.memo(
  ({
    wixExperiments,
    content,
    plugins,
    mobile,
    locale,
    cssOverride,
    biSettings,
    seoSettings,
    instance,
    linkSettings,
    theme,
  }) => {
    return (
      <WixRicosViewer
        wixExperiments={wixExperiments}
        content={content}
        plugins={plugins}
        isMobile={mobile}
        locale={locale}
        cssOverride={cssOverride}
        biSettings={biSettings}
        seoSettings={seoSettings}
        instance={instance}
        linkSettings={linkSettings}
        theme={theme}
      />
    );
  },
);

const RichContentViewer = React.memo(
  ({
    isSeo,
    isMobile,
    origin,
    buttonClicked,
    ricosBiParams,
    pluginsConfig,
    contentId,
    experiments,
    initialState,
    locale,
    instance,
    isMembersAreaInstalled,
    rceTheme,
  }) => {
    const [content] = React.useState(initialState);
    const [mention, setMention] = React.useState(null);
    const hasMention = mention !== null;

    const biSettings = React.useMemo(
      () => ({
        consumer: 'communities-forum-client',
        platform: 'Livesite',
        defaultParams: ricosBiParams,
        biCallbacks: {
          onViewerAction: (pluginId, actionName, value) => {
            switch (value) {
              case 'expand_image':
                return buttonClicked({ name: 'img_expand', origin });
              default:
                return;
            }
          },
        },
        contentId,
      }),
      [ricosBiParams, buttonClicked],
    );

    const onMentionHover = React.useCallback(
      (mention, ref) => {
        MemberCardAsyncLoadable.load().then(() => {
          setMention({ ...mention, ref });
        });
      },
      [setMention],
    );

    const config = React.useMemo(
      () =>
        mapValues(
          {
            ...pluginsConfig,
            ...(pluginsConfig[PLUGINS.MENTIONS]
              ? {
                  [PLUGINS.MENTIONS]: {
                    ...pluginsConfig[PLUGINS.MENTIONS],
                    viewerConfig: {
                      ...pluginsConfig[PLUGINS.MENTIONS].viewerConfig,
                      onMentionHover,
                    },
                  },
                }
              : {}),
          },
          (config) => config.viewerConfig,
        ),
      [pluginsConfig, onMentionHover],
    );

    const plugins = React.useMemo(() => mapConfigToPlugins(config), [config]);

    const linkSettings = React.useMemo(
      () => ({
        anchorTarget: '_blank',
        rel: { nofollow: true, sponsored: false, ugc: true },
      }),
      [],
    );

    const viewer = (
      <div className={styles.container}>
        <RicosWrapper
          content={content}
          wixExperiments={experiments}
          plugins={plugins}
          mobile={isMobile}
          locale={locale}
          biSettings={biSettings}
          seoSettings={isSeo}
          instance={instance}
          linkSettings={linkSettings}
          theme={rceTheme}
        />
      </div>
    );

    return (
      <>
        {isMembersAreaInstalled && hasMention && mention ? (
          <MemberCardAsyncLoadable
            key={mention.id} // to remount the card and prevent showing old data in the card, while loading new
            customTargetRef={mention.ref}
            viewedMemberId={mention.id}
            viewedMemberSlug={mention.slug}
          />
        ) : null}
        {viewer}
      </>
    );
  },
);

const mapRuntimeToProps = (state, ownProps, actions, host) => {
  const location = getLocation(state);
  const sectionUrl = location.sectionUrl;
  return {
    style: host.style,
    pageUrl: getOuterUrl(location.pathname, sectionUrl),
    isSeo: isSeo(state),
    isEditor: isEditor(state),
    isPreview: isPreview(state),
    isMembersAreaInstalled: isMemberAreaInstalled(state),
    isPinterestPinEnabled: getIsPinterestPinEnabled(state, host.style),
    sectionUrl,
    locale: getLanguage(state),
    isSSR: isSSR(state),
    buttonClicked: actions.buttonClicked,
    experiments: state.experiments,
    ricosBiParams: getRicosBiParams(state),
    instance: getInstance(state),
  };
};

export default flowRight(
  withRceTheme,
  withRcePluginsConfig,
  connect(mapRuntimeToProps),
  withDeviceType,
  withAuth,
)(RichContentViewer);
