import { map } from 'lodash';
import { PLUGINS } from '@wix/communities-forum-client-commons';

import {
  pluginLinkLoadable as pluginLink,
  pluginImageLoadable as pluginImage,
  pluginVideoLoadable as pluginVideo,
  pluginDividerLoadable as pluginDivider,
  pluginHtmlLoadable as pluginHtml,
  pluginMentionsLoadable as pluginMentions,
  pluginGiphyLoadable as pluginGiphy,
  pluginFileUploadLoadable as pluginFileUpload,
  pluginLinkPreviewLoadable as pluginLinkPreview,
  pluginVerticalEmbedLoadable as pluginVerticalEmbed,
  pluginCollapsibleListLoadable as pluginCollapsibleList,
  pluginPollLoadable as pluginPoll,
  pluginGalleryLoadable as pluginGallery,
} from '@wix/ricos/loadable';

import {
  pluginHashtagViewer as pluginHashtag,
  pluginCodeBlockViewer as pluginCodeBlock,
  pluginTextColorViewer as pluginTextColor,
  pluginTextHighlightViewer as pluginTextHighlight,
  pluginEmojiViewer as pluginEmoji,
  pluginSpoilerViewer as pluginSpoiler,
} from '@wix/ricos';

import '@wix/ricos/css/plugin-hashtag-viewer.global.css';
import '@wix/ricos/css/plugin-spoiler-viewer.global.css';

export const PLUGIN_MODULES = {
  [PLUGINS.IMAGE]: pluginImage,
  [PLUGINS.VIDEO]: pluginVideo,
  [PLUGINS.LINK]: pluginLink,
  [PLUGINS.HASHTAG]: pluginHashtag,
  [PLUGINS.DIVIDER]: pluginDivider,
  [PLUGINS.HTML]: pluginHtml,
  [PLUGINS.MENTIONS]: pluginMentions,
  [PLUGINS.CODE_BLOCK]: pluginCodeBlock,
  [PLUGINS.TEXT_COLOR]: pluginTextColor,
  [PLUGINS.TEXT_HIGHLIGHT]: pluginTextHighlight,
  [PLUGINS.GIPHY]: pluginGiphy,
  [PLUGINS.FILE_UPLOAD]: pluginFileUpload,
  [PLUGINS.EMOJI]: pluginEmoji,
  [PLUGINS.LINK_PREVIEW]: pluginLinkPreview,
  [PLUGINS.VERTICAL_EMBED]: pluginVerticalEmbed,
  [PLUGINS.SPOILER]: pluginSpoiler,
  [PLUGINS.COLLAPSIBLE_LIST]: pluginCollapsibleList,
  [PLUGINS.POLLS]: pluginPoll,
  [PLUGINS.GALLERY]: pluginGallery,
};

export const mapConfigToPlugins = (config) =>
  map(PLUGIN_MODULES, (plugin, key) => {
    const pluginConfig = config[key];
    return pluginConfig ? plugin(pluginConfig) : plugin();
  });
