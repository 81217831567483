import { PluginConfigRoot } from '@wix/comments-ooi-client';
import { PLUGINS } from '@wix/communities-forum-client-commons';
import { mapValues, fromPairs } from 'lodash';
import { viewerPluginModules } from './viewer-plugins';
import {
  IMAGE_TYPE,
  VIDEO_TYPE,
  LINK_TYPE,
  TEXT_COLOR_TYPE,
  HTML_TYPE,
  FILE_UPLOAD_TYPE,
  POLL_TYPE,
  GALLERY_TYPE,
  DIVIDER_TYPE,
  COLLAPSIBLE_LIST_TYPE,
  GIPHY_TYPE,
  VERTICAL_EMBED_TYPE,
  MENTION_TYPE,
  EMOJI_TYPE,
  HASHTAG_TYPE,
  CODE_BLOCK_TYPE,
  LINK_PREVIEW_TYPE,
  SPOILER_TYPE,
  TEXT_HIGHLIGHT_TYPE,
} from '@wix/ricos';
import { PluginsConfig } from '../../hoc/with-rce-plugins-config';

const editorPlugins = import(
  /* webpackChunkName: "ricos-editor-plugins" */ './editor-plugins-lazy'
);

export const pluginTypeMap = {
  [PLUGINS.LINK]: LINK_TYPE,
  [PLUGINS.HASHTAG]: HASHTAG_TYPE,
  [PLUGINS.MENTIONS]: MENTION_TYPE,
  [PLUGINS.TEXT_COLOR]: TEXT_COLOR_TYPE,
  [PLUGINS.LINK_PREVIEW]: LINK_PREVIEW_TYPE,
  [PLUGINS.TEXT_HIGHLIGHT]: TEXT_HIGHLIGHT_TYPE,
  [PLUGINS.IMAGE]: IMAGE_TYPE,
  [PLUGINS.VIDEO]: VIDEO_TYPE,
  [PLUGINS.FILE_UPLOAD]: FILE_UPLOAD_TYPE,
  [PLUGINS.GIPHY]: GIPHY_TYPE,
  [PLUGINS.EMOJI]: EMOJI_TYPE,
  [PLUGINS.CODE_BLOCK]: CODE_BLOCK_TYPE,
  [PLUGINS.COLLAPSIBLE_LIST]: COLLAPSIBLE_LIST_TYPE,
  [PLUGINS.DIVIDER]: DIVIDER_TYPE,
  [PLUGINS.HTML]: HTML_TYPE,
  [PLUGINS.VERTICAL_EMBED]: VERTICAL_EMBED_TYPE,
  [PLUGINS.SPOILER]: SPOILER_TYPE,
  [PLUGINS.POLLS]: POLL_TYPE,
  [PLUGINS.GALLERY]: GALLERY_TYPE,
};

export const editorPluginOrder = [
  PLUGINS.IMAGE,
  PLUGINS.VIDEO,
  PLUGINS.FILE_UPLOAD,
  PLUGINS.GIPHY,
  PLUGINS.EMOJI,
  PLUGINS.CODE_BLOCK,
  PLUGINS.COLLAPSIBLE_LIST,
  PLUGINS.DIVIDER,
  PLUGINS.HTML,
  PLUGINS.VERTICAL_EMBED,
  PLUGINS.SPOILER,
  PLUGINS.POLLS,
  PLUGINS.LINK,
  PLUGINS.HASHTAG,
  PLUGINS.MENTIONS,
  PLUGINS.TEXT_COLOR,
  PLUGINS.LINK_PREVIEW,
  PLUGINS.TEXT_HIGHLIGHT,
];

export const buildCommentsPluginConfig = ({
  pluginsConfig,
  filterEnabledPlugins,
}: {
  pluginsConfig: PluginsConfig;
  filterEnabledPlugins: (plugins: string[]) => string[];
}): PluginConfigRoot => {
  const loadEditorPluginsAsync = async () => {
    const { editorPluginModules } = await editorPlugins;
    const enabledPlugins = filterEnabledPlugins(editorPluginOrder);

    const pluginConfig = fromPairs(
      enabledPlugins.map((pluginType) => [
        pluginTypeMap[pluginType],
        {
          editorModule: editorPluginModules[pluginType],
          editorConfig: pluginsConfig[pluginType]?.editorConfig,
        },
      ]),
    );

    return pluginConfig;
  };

  return {
    loadEditorPluginsAsync,
    plugins: mapValues(viewerPluginModules, (plugin, key) => ({
      viewerModule: plugin,
      viewerConfig: pluginsConfig[key],
    })),
    type: 'async' as const,
  };
};
