import { URI } from '@wix/communities-forum-client-commons';
import getOuterUrl from '../../services/get-outer-url';
import { encodeQuery } from '../../services/query-encoding';

export const MAX_PINTEREST_IMAGE_SIZE = 5000;
export const HTML_IFRAME_SRC =
  'https://static.parastorage.com/unpkg/@wix/communities-forum-rce@1.0.132/dist/statics/html-plugin-embed.html';

const normalizeDecoratedText = (decoratedText) =>
  decoratedText[0] === '#' ? decoratedText : `#${decoratedText}`;

export const createHashtagPath = (decoratedText) =>
  `/search/${encodeQuery(normalizeDecoratedText(decoratedText))}`;

export const createHashtagHref = (sectionUrl) => (decoratedText) =>
  new URI(getOuterUrl(createHashtagPath(decoratedText), sectionUrl));
