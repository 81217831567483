import { flowRight } from 'lodash';
import React from 'react';
import RichContentViewer from '../rich-content-viewer';
import withFontClassName from '../../hoc/with-font-class-name';
import { Post } from '../../types';
import withDeviceType from '../../hoc/with-device-type';

type PostContentProps = {
  className?: string;
  post: Post;
  type: string;
  contentFontClassName: string;
  containerWidth: number;
  isMobile: boolean;
};

export const PostContent: React.FC<PostContentProps> & {
  POST_PAGE: 'postPage';
  FEED_PAGE: 'feedPage';
} = ({ className, post, type, contentFontClassName }) => {
  let postBody;

  if (typeof post.content === 'object') {
    postBody = (
      <div className={contentFontClassName}>
        <RichContentViewer
          key={`${post._id}-${post.editedDate}`}
          initialState={post.content}
          readOnly
          origin="post"
          type={type}
          contentId={post._id}
        />
      </div>
    );
  }

  return (
    <article className={className}>
      <div className="post-content__body" tab-index="0">
        {postBody}
      </div>
    </article>
  );
};

PostContent.POST_PAGE = 'postPage';
PostContent.FEED_PAGE = 'feedPage';

export default flowRight(withFontClassName, withDeviceType)(PostContent);
